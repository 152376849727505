import { SESSION_IDS } from "../common/constants";
import { Wrapper } from "./styled";
import { Map } from './map';

export const JourneySession = () => {
    return <Wrapper id={SESSION_IDS.journey}>
    <h2>Journey</h2>
    <p>Click pin for details. Some of them support to download highlights in the trip!</p>
    <Map />
  </Wrapper>;
};