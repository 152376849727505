import { Container } from "./styled";
// import { ContactSession } from '../ContactSession/ContactSession';
import { ProjectSession } from "ProjectSession/ProjectSession";
import { HeaderComponent } from "./HeaderComponent";
import { ProfileSession } from "ProfileSession/ProfileSession";
import { FooterComponent } from "./Footer";
import { JourneySession } from "JourneySession/JourneySession";
import { FunThingsSession } from "FunThingsSession/FunThingsSession";


export const Home = () => {
  return (
    <Container>
      <HeaderComponent />
      <ProfileSession />
      <FunThingsSession />
      <JourneySession />
      <ProjectSession />
      {/* <ContactSession /> */}
      <FooterComponent />
    </Container>
  );
};
