import styled from "styled-components";

export const Wrapper = styled.section`
  padding: 40px 20px;
  background-color: #f9f9f9;

  h2 {
    margin-bottom: 30px;
  }

  img {
    width: 100%;
    border-radius: 8px;
  }

  h3 {
    margin: 15px 0 10px;
  }

  p {
    font-size: 0.9rem;
    color: #555;
  }
`;

export const ItemWrapper = styled.div`
  padding: 20px;
  border-radius: 10px;
  max-width: 900px;
  margin: 0 auto;
  cursor: pointer;
`;

export const ImageGallery = styled.div`
  width: 100%;
  margin-right: 20px;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  text-align: start;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  text-align: start;
  font-size: 1rem;
  color: #555;
`;
