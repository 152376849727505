const stringArr = ['配方: 侧柏叶200g,何首乌50g,桑叶50g,无患子100g,女贞子100g,皂角 150g',
'存储方法：未使用时请置于冰箱冷藏，以保持最佳效果。',
'使用步骤：',
'1. 取用：从冰箱中取出洗发水。',
'2. 使用：将适量洗发水直接倒在头皮上。',
'3. 按摩：轻轻按摩头皮，确保洗发水均匀分布并充分接触头皮。',
'4. 冲洗：用温水彻底冲洗干净。',
'注意事项：',
'* 泡沫：本产品泡沫较少，这是正常现象，不影响清洁效果。',
'* 无需添加：无需再使用其他洗发水或护发素，本产品已包含全面的头发护理成分。',
'* 干发方法：洗发后用毛巾擦干，建议自然晾干。若需使用吹风机，也可吹干。',
'温馨提示：',
'* 请在每次使用后将未用完的洗发水放回冰箱冷藏。',
'* 若出现任何不适，请立即停止使用并咨询医生。'];

export const HerbalShampoo = () => {
    return <div style={{ textAlign: 'start'}}>{stringArr.map((sentence) => <div>{sentence}</div>)}</div>;
};
