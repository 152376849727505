const stringArr = [
    '1. Calculate basic calories for each day',
    '2. Design exercise plan and meal plan. Outake calories > intake calories => losing weight',
    '3. Meal prep and follow exercise plan. Losing 1kg/week is a healthy speed',
    '4. Once weight has big changes, start from step 1'
];

export const CaloriesDeficit = () => {
    return <div style={{ textAlign: 'start'}}>{stringArr.map((sentence) => <div>{sentence}</div>)}</div>;
};
