import { useContext } from "react";

import { AppContext } from "AppContext";

import { FULL_NAME, SESSION_IDS } from "../common/constants";
import { Header } from "./styled";

export const HeaderComponent = () => {
  const { theme } = useContext(AppContext);
  return (
    <Header theme={theme}>
      <h1>{FULL_NAME}</h1>
      <nav>
        <ul>
          <li>
            <a href={`#${SESSION_IDS.about}`}>About</a>
          </li>
          <li>
            <a href={`#${SESSION_IDS.funThings}`}>Fun Things</a>
          </li>
          <li>
            <a href={`#${SESSION_IDS.journey}`}>Journey</a>
          </li>
          <li>
            <a href={`#${SESSION_IDS.project}`}>Project</a>
          </li>
          {/* <li>
            <a href={`#${SESSION_IDS.contact}`}>Contact</a>
          </li> */}
        </ul>
      </nav>
    </Header>
  );
};
