import { THEME } from "../common/constants";

export const BASIC = {
    [THEME.LIGHT]: {
      "backgroundColor": "#f4f4f4",
      "color": "#333333",
      "headerColor": '#f4f4f4',
      "secondaryTextColor": "#666666",
      // "primary-accent-color": "#007ACC",
      // "secondary-accent-color": "#FF5722",
      // "card-background-color": "#F8F8F8",
      // "border-color": "#E0E0E0"
    },
    [THEME.DARK]: {
      "backgroundColor": "#121212",
      "color": "#E0E0E0",
      "headerColor": '#f4f4f4',
      "secondaryTextColor": "#B3B3B3",
      // "primary-accent-color": "#1E88E5",
      // "secondary-accent-color": "#FF8A65",
      // "card-background-color": "#1E1E1E",
      // "border-color": "#333333"
    }
  }
  