import {ColorScheme} from '@vis.gl/react-google-maps';

export const FULL_NAME = "Yujie Zeng";
export enum SESSION_IDS {
    contact='contact',
    about='about',
    project='project',
    journey='journey',
    funThings='funThings',
};

export const THEME = {
    DARK: ColorScheme.DARK,
    LIGHT: ColorScheme.LIGHT
}