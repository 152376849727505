import { SESSION_IDS } from "../common/constants";
import { Wrapper } from "./styled";
import checkerGame from "./images/checker-game.png";
import fengshui from "./images/fengshui.png";
import makeyourfragrance from "./images/makeyourfragrance.png";

const data = [
  {
    img: checkerGame,
    link: "https://checker-game-mocha.vercel.app/",
    title: "Checker Game",
    description:
      "A sleek and contemporary website design for a tech startup, focusing on user engagement and visual storytelling.",
  },
  {
    img: fengshui,
    link: "https://sites.google.com/view/fengshui/%E9%A6%96%E9%A1%B5",
    title: "Fortune-teller",
    description:
      "A user-friendly mobile application design with intuitive navigation and engaging features for seamless user experience.",
  },
  {
    img: makeyourfragrance,
    title: "Make Your Fragrance",
    description:
      "An efficient and attractive ecommerce platform that offers a seamless shopping experience with an organized product layout.",
  },
];

export const ProjectSession = () => {
  return (
    <Wrapper id={SESSION_IDS.project}>
      <h2>Side Project</h2>
      <p>
        I am a creative and dedicated professional with expertise in web
        development and design. I am passionate about building beautiful and
        functional websites. Welcome to my site! Here you can learn more about
        my work and feel free to reach out to me.
      </p>
      <div className="work-container">
        {data.map((item, index) => (
          <div className="project-item" key={index}>
            <img src={item.img} alt={item.title} />
            <a href={item.link}>{item.title}</a>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};
