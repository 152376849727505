import { useState } from "react";

import {
  ItemWrapper,
  Image,
  ImageGallery,
  Text,
  Title,
  Description,
} from "./styled";

import { Modal } from "./Modal";
import { datumType, emptyDatum, data } from "./constants";

export const Item = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDatum, setSelectedDatum] = useState<datumType>(emptyDatum);

  const openModal = (datum: datumType) => () => {
    setIsModalOpen(true);
    setSelectedDatum(datum);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDatum(emptyDatum);
  };

  return (
    <div>
      {data.map((datum: datumType) => {
        return (
          <ItemWrapper
            role="button"
            onClick={openModal(datum)}
            key={datum.title}
          >
            <Title>{datum.title}</Title>
            <Text>
              <ImageGallery>
                <Image src={datum.image} alt={datum.title} />
              </ImageGallery>
              <Description>{datum.description}</Description>
            </Text>
          </ItemWrapper>
        );
      })}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={selectedDatum?.title}
      >
        <selectedDatum.details />
      </Modal>
    </div>
  );
};
