import { SESSION_IDS } from "../common/constants";
import {
  ProfileContainer,
  ProfileDescription,
  ProfileHeading,
  ProfileImage,
  ProfileText,
} from "./styled";
import profile from "./images/profile.jpeg";

export const ProfileSession = () => {
  return (
    <ProfileContainer id={SESSION_IDS.about}>
      <ProfileImage src={profile} alt="Profile" />
      <ProfileText>
        <ProfileHeading>Welcome to My World!</ProfileHeading>
        <ProfileDescription>
          <p>
            Hello and welcome to my little corner of the internet! I'm an
            adventurer at heart, always seeking out new experiences and places
            to explore. This website is my digital diary, a place where I
            document my interesting life, exciting travels, and the various
            hobbies and projects I dive into along the way. From scenic hikes in
            breathtaking landscapes to experimenting with new recipes in the
            kitchen, you'll find a mix of my travel plans, personal stories, and
            unique endeavors. Whether you're here to get some travel
            inspiration, follow along with my latest adventures, or just see
            what I'm up to, I hope you find something that sparks your interest.
          </p>
          <p>
            Thanks for stopping by, and I hope you enjoy the journey as much as
            I do!
          </p>
        </ProfileDescription>
      </ProfileText>
    </ProfileContainer>
  );
};
