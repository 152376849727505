import { createContext, useState } from "react";
import { THEME } from "./common/constants";

// Create the context
export const AppContext = createContext();

// Create the provider component
export const AppProvider = ({ children }) => {
  const defaultTheme =
    window &&
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? THEME.DARK
      : THEME.LIGHT;
  const [theme, setTheme] = useState(defaultTheme);

  const toggleTheme = (theme) => {
    setTheme(theme);
  };

  return (
    <AppContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </AppContext.Provider>
  );
};
