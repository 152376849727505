import styled from "styled-components";
import { BASIC } from "./constants";
import { styledProps } from "Common/types";

export const Container = styled.div`
  text-align: center;
`;

export const Header = styled.header<styledProps>(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    backgroundColor: BASIC[theme].backgroundColor,
    color: BASIC[theme].headerColor,

    h1: {
      margin: 0,
    },

    nav: {
      ul: {
        listStyle: "none",
        display: "flex",
        gap: "15px",
        a: {
          textDecoration: "none",
          fontWeight: "bold",
          color: BASIC[theme].secondaryTextColor,
        },
      },
    },
  };
});

export const Footer = styled.footer<styledProps>(({ theme }) => {
  return {
    backgroundColor: BASIC[theme].backgroundColor,
    padding: "20px",
    textAlign: "center",
    color: BASIC[theme].headerColor,

    a: {
      marginLeft: "15px",
      color: BASIC[theme].headerColor,
      textDecoration: "none",
      fontSize: "1.2rem",
    },
  };
});
