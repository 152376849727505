export const VISITED_PLACES = [
  // North America
  { name: "Yellowknife", position: { lat: 62.4540, lng: -114.3718 } },
  { name: "Osaka", position: { lat: 34.6937, lng: 135.5023 } },
  { name: "Tokyo", position: { lat: 35.6895, lng: 139.6917 } },
  { name: "North Carolina", position: { lat: 35.7596, lng: -79.0193 } },
  { name: "Washington, D.C.", position: { lat: 38.9072, lng: -77.0369 } },
  { name: "San Francisco", position: { lat: 37.7749, lng: -122.4194 } },
  { name: "Seattle", position: { lat: 47.6062, lng: -122.3321 } },
  { name: "Portland", position: { lat: 45.5152, lng: -122.6784 } },
  { name: "Yellowstone National Park", position: { lat: 44.4280, lng: -110.5885 } },
  { name: "Tofino", position: { lat: 49.1520, lng: -125.9031 } },
  { name: "Montreal", position: { lat: 45.5017, lng: -73.5673 } },
  // Europe
  { name: "Edinburgh", position: { lat: 55.9533, lng: -3.1883 } },
  { name: "Rome", position: { lat: 41.8719, lng: 12.5674 }, contents: `Florence
Recommended restaurants
Acqua Al 2 - Via della Vigna Vecchia, 40r, 50122 Firenze FI, Italy - beef, tiramisu
il Mercato Centrale Firenze - Piazza del Mercato Centrale, Via dell'Ariento, 50123 Firenze FI, Italy - nice food located in the second floor; don't miss beef tripe there

Recommended activities
Enjoy renaissance masterpieces in art museums
Handmade jewelry on Ponte Vecchio

Rome
Recommended restaurants
All'Antico Vinaio - Piazza della Maddalena, 3, 00186 Roma RM, Italy - come with a lot of Proscuitto
Vladimiro - Via Aurora, 37, 00187 Roma RM, Italy - beef tastes sweet and special; spaghetti carbonara

Recommended activities
Vatican city and museums
Famous architectures

Recommended hotel
Divina Luxury Hotel - Via Aurora, 29, 00187 Roma RM, Italy - great location and nice service`, filename: 'Italy.txt' },
  // Asia
  { name: "Suzhou", position: { lat: 31.2989, lng: 120.5853 } },
];

export const VANCOUVER_LOCATION = { lat: 49.2827, lng: -123.1207 };  // Vancouver